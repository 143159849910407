import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Fetch_Url } from "../../../Context/Context";
// mui
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

export default function BankCard() {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const navigate = useNavigate();
  const [bankinfo, setBankinfo] = useState([]);

  const bankCard = () => {
    axios
      .post(
        `${Fetch_Url}/bet.php?action=bankcard&user=${window?.$loginData}`,
        {}
      )
      .then((resp) => {
        console.log(resp, "bank Data");
        setBankinfo(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCard = () => {
    axios
      .post(
        `${Fetch_Url}/bet.php?action=deletebankcard&user=${window?.$loginData}`,
        {}
      )
      .then((resp) => {
        // console.log(resp, "delete bank card");
        // window?.location?.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="bankPopup">
        <h5>Select</h5>
        <h4>
          <i className="fa fa-pencil"></i>Edit
        </h4>
        <h4 onClick={deleteCard}>
          <i className="fa fa-trash"></i>Delete
        </h4>
      </div>
    </Box>
  );

  useEffect(() => {
    bankCard();
  }, []);

  return (
    <>
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate("/profile");
          }}
        ></i>
        <h3>Bank Card</h3>
        <i
          className="fa fa-plus"
          onClick={() => {
            navigate("/addbankcard");
          }}
        ></i>
      </div>
      {bankinfo?.map((item) => {
        return (
          <>
            <div className="bankBox">
              <div className="bankIcon">
                <i className="fa fa-wallet"></i>
              </div>
              <div className="bankTxt">
                <h4>{item?.name}</h4>
                <h4>{item?.upi}</h4>
              </div>
              <div className="bankIcon2">
                {["bottom"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <h4
                    // onClick={toggleDrawer(anchor, true)}
                    >
                      i
                    </h4>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

/* BankCard CSS */
