import { useNavigate } from "react-router-dom";

export default function Address() {
  const navigate = useNavigate();

  return (
    <>
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Address</h3>
        <i
          className="fa fa-plus"
          onClick={() => {
            navigate("/addaddress");
          }}
        ></i>
      </div>
    </>
  );
}
