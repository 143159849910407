import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Fetch_Url } from "../../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Gift() {
  const navigate = useNavigate();
  const [giftCode, setGiftCode] = useState("");

  const handleApplyGiftCode = async () => {
    if (!giftCode.trim()) {
      toast.error("Please enter a valid gift code.");
      return;
    }

    try {
        const username = localStorage.getItem("loginData");

      const response = await axios.post(`${Fetch_Url}/Index.php`, {
        aksi: "applyGiftCouponCode",
        username,
        coupon_code: giftCode.trim(),
      });

      if (response.data.success) {
        toast.success(response.data.msg || "Coupon applied successfully!");
        // Optional: Navigate or refresh user's balance
        // navigate("/wallet");
      } else {
        toast.error(response.data.msg || "Failed to apply the coupon code.");
      }
    } catch (error) {
      console.error("Error applying gift code:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="minibg">
        <div className="miniHeadforWallet">
          <i
            className="fa fa-arrow-left"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <h3>Gift</h3>
        </div>
      </div>
      <div>
        <img
          src="happy/GiftBanner.png"
          className="img img-responsive img-fluid"
          style={{ height: "auto" }}
          alt="Gift Banner"
        />
      </div>
      <div className="px-2">
        <div className="GiftCodeHead" style={{ marginTop: "10px" }}>
          <h4>Hello!</h4>
          <h4>We Have A Gift For You... 🎁</h4>
          <div className="GiftCodeInput">
            <label
              style={{
                marginBottom: "10px",
                marginLeft: "10px",
                fontSize: "14px",
              }}
            >
              Please enter the gift code below:
            </label>
            <input
              type="text"
              placeholder="Please enter gift code"
              value={giftCode}
              onChange={(e) => {
                setGiftCode(e.target.value);
              }}
              className="form-control"
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div className="GiftCodeBtn">
            <button
              onClick={handleApplyGiftCode}
              className="btn btn-primary"
              style={{ width: "100%" }}
            >
              Apply Gift Code
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
