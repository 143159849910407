import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Fetch_Url } from "../../../Context/Context";

export default function Transactions() {
  const navigate = useNavigate();
  const [transdata, setTransdata] = useState([]);

  const transRecord = () => {
    axios
      .post(
        `${Fetch_Url}/bet.php?action=trans&user=${window.$loginData}&page1=1`,
        {}
      )
      .then((resp) => {
        // console.log(resp, "resp data");
        setTransdata(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    transRecord();
  }, []);

  return (
    <>
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Transactions</h3>
      </div>
      <div>
        {transdata.map((data) => {
          return (
            <>
              <div className="recordBox">
                <div className="recordText">
                  <h5>&#x20b9; {data?.amount}</h5>
                </div>
                <div className="recordText">
                  <h5>Place Order</h5>
                  <h5>{data?.res}</h5>
                </div>
                <div className="recordText">
                  <h5>{data?.time}</h5>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

/* RechargeRecord CSS Used In Transactions CSS*/
