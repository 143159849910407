import { useNavigate } from "react-router-dom";

export default function AddADDRESS() {
  const navigate = useNavigate();

  return (
    <>
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Add Address</h3>
      </div>
      <div className="addInputCont">
        <div className="bankInput">
          <label>Full Name</label>
          <input type="text" />
        </div>
        <div className="bankInput">
          <label>Mobile Number</label>
          <input type="text" />
        </div>
        <div className="bankInput">
          <label>Pincode</label>
          <input type="text" />
        </div>
        <div className="bankInput">
          <label>State</label>
          <input type="text" />
        </div>
        <div className="bankInput">
          <label>Town/City</label>
          <input type="text" />
        </div>
        <div className="bankInput">
          <label>Detail Address</label>
          <input type="text" />
        </div>
      </div>
      <div className="signinBtnCard">
        <button>Continue</button>
      </div>
    </>
  );
}

/* Add Address CSS */
// AddBankCard CSS used in Add Address CSS.
