import { useNavigate } from "react-router-dom";

export default function AddComplaints() {
  const navigate = useNavigate();

  return (
    <>
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Add Complaints & Suggestion</h3>
      </div>
      <div className="compCont">
        <div className="bankInput">
          <label>Type</label>
          <input type="text" />
        </div>
        <div className="bankInput">
          <label>Out ID</label>
          <input type="text" />
        </div>
        <div className="bankInput">
          <label>WhatsApp</label>
          <input type="text" />
        </div>
        <div className="bankInput">
          <label>Description</label>
          <input type="text" />
        </div>
        <div className="compText">
          <h5>Service 10:00~17:00, Mon~Fri about 1~5 Business Days</h5>
        </div>
        <div className="signinBtnCard">
          <button>Continue</button>
        </div>
      </div>
    </>
  );
}

/* Add Complaints CSS */
/* Add Address CSS & AddBankCard CSS used in Add Complaints CSS */
