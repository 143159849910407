import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetch_Url } from "../../../Context/Context";

export default function SignUp() {
  const navigate = useNavigate();
  const [number, setNumber] = useState([]);
  const [password, setPassword] = useState([]);
  const [recode, setRecode] = useState([]);
  const [queryParameters] = useSearchParams();
  // const [otp, setOtp] = useState([]);

  // const getOtp = () => {
  //   navigator.vibrate(100);
  //   number?.length === 10
  //     ? axios
  //         .post(`${Fetch_Url}/otp.php?num=${number}`, {})
  //         .then((resp) => {
  //           console.log(resp, "getOTP Data");
  //           toast.success("OTP has been sent", {
  //             position: toast.POSITION.BOTTOM_LEFT,
  //           });
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         })
  //     : toast.error("Please Enter Valid Mobile Number", {
  //         position: toast.POSITION.BOTTOM_LEFT,
  //       });
  // };

  const Register = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata?.append("username", number);
    formdata?.append("password", password);
    // formdata?.append("code", "");
    formdata?.append("refcode", recode);
    number?.length === 10 &&
      //  otp?.length > 0 && 
      password?.length > 0
      ? axios
        .post(`${Fetch_Url}/bet.php?action=register`, formdata, {})
        .then((resp) => {
          console.log(resp, "register Data");
          if (resp?.data[0]?.status === "Incorrect otp") {
            toast.error("Incorrect OTP", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else if (resp?.data[0]?.status === "User already exists") {
            toast.error("User Already Exists", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else {
            toast.success("Registration Successfull", {
              position: toast.POSITION.BOTTOM_LEFT,
            }) &&
              setTimeout(() => {
                navigate("/signin");
              }, 1500);
          }
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Input can't be Empty", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  useEffect(() => {
    setRecode(queryParameters.get("r_code"));
  }, [queryParameters]);
  // console.log(recode, "search recode");

  return (
    <>
      <ToastContainer />
      <div className="slotHeadText">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Register</h3>
      </div>
      <div className="signinHead">
        <h4>Please register by phone number</h4>
      </div>
      <div className="signinBox">
        <div className="signinInput">
          <input
            type="number"
            placeholder="Mobile Number"
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
        </div>
        {/* <div className="otpInput">
          <input
            type="number"
            placeholder="Verification Code"
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
          <h4 onClick={getOtp}>OTP</h4>
        </div> */}
        <div className="signinInput">
          <input
            type="text"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="signinInput">
          <input
            type="text"
            placeholder="Recommendation Code"
            value={recode}
            onChange={(e) => {
              setRecode(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="privacyText">
        <h4>
          <i className="fa fa-check"></i>I agree
          <span>Privacy & Policy</span>
        </h4>
      </div>
      <div className="signupBtns">
        <Link to="/signin">
          <div
            className="signupBtn"
            onClick={() => {
              navigator.vibrate(100);
            }}
          >
            <button>Login</button>
          </div>
        </Link>
        <div className="signupBtn" onClick={Register}>
          <button>Register</button>
        </div>
      </div>
    </>
  );
}

/* SignUp CSS */
