import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
// MUI
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactAudioPlayer from "react-audio-player";
import { andar_Url } from '../../Context/Context';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 320,
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "20px"
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const moneydata = [
    {
        id: 1,
        money: 10,
    },
    {
        id: 2,
        money: 100,
    },
    {
        id: 3,
        money: 1000,
    },
    {
        id: 4,
        money: 10000,
    },
];

export default function AndarBahar() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [respopup, setRespopup] = React.useState(false);
    const [rule, setRule] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [seconds, setSeconds] = React.useState(60);
    const [minute, setMinute] = React.useState(0);
    const [second, setSecond] = React.useState(0);
    const [userdetailWin, setUserdetailWin] = React.useState([]);
    const [resultinfo, setResultinfo] = React.useState([]);
    const [cardinfo, setCardinfo] = React.useState([]);
    const [randomData, setRandomData] = React.useState([]);
    const [randomData2, setRandomData2] = React.useState([]);
    const [betdata, setBetdata] = React.useState([]);
    const [recordinfo, setRecordinfo] = React.useState([]);
    const [randomcard, setRandomcard] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [page2, setPage2] = React.useState(1);
    const [intvalue, setIntvalue] = React.useState(1);
    const [intvalue2, setIntvalue2] = React.useState(1);
    const [intvalue3, setIntvalue3] = React.useState(1);
    const [greenbtn, setGreenbtn] = React.useState({
        id: 1,
        money: 10,
    });
    const [violetbtn, setVioletbtn] = React.useState({
        id: 1,
        money: 10,
    });
    const [redbtn, setRedbtn] = React.useState({
        id: 1,
        money: 10,
    });

    let contractMoney = greenbtn?.money * intvalue;
    // console.log(contractMoney, "contractMoney");

    let contractMoney2 = violetbtn?.money * intvalue2;
    // console.log(contractMoney2, "contractMoney2");

    let contractMoney3 = redbtn?.money * intvalue3;
    // console.log(contractMoney3, "contractMoney3");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const userInfo = () => {
        axios
            .post(`${andar_Url}/bet.php?action=info&user=${window.$loginData}`, {})
            .then((resp) => {
                console.log(resp, "resp userInfo data");
                setUserdetailWin(resp?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [bal] = userdetailWin;
    // console.log(bal, "bal");

    const periodID = userdetailWin?.find((item) => {
        return item?.period;
    });
    // console.log(periodID, "periodID");

    const allRecord = React.useCallback(async () => {
        const res = fetch(
            `${andar_Url}/bet.php?action=resultrec&page=${page2}`
        ).then((res) => res.json());
        const json = await res;
        console.log(json, "res allRecord data");
        setRecordinfo(json);
    }, [page2]);

    React.useEffect(() => {
        allRecord();
    }, [allRecord]);

    const betRecord = React.useCallback(async () => {
        const res = fetch(
            `${andar_Url}/bet.php?action=betrec&user=${window?.$loginData}&page1=${page}`
        ).then((res) => res.json());
        const json = await res;
        console.log(json, "res betRecord data");
        setBetdata(json);
    }, [page]);

    React.useEffect(() => {
        betRecord();
    }, [betRecord]);

    const andarResult = () => {
        axios
            .post(`${andar_Url}/declaredResult.php`, {
                aksi: "proses_update_result",
                Username: window.$loginData
            })
            .then((resp) => {
                console.log(resp, "resp andarResult data");
                setResultinfo(resp?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getCard = () => {
        axios
            .post(`${andar_Url}/period.php`, {
                aksi: "proses_rand_card",
                PeriodId: periodID?.period
            })
            .then((resp) => {
                console.log(resp, "resp getCard data");
                setCardinfo(resp?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const updatePeriod = () => {
        axios
            .post(`${andar_Url}/period.php`, {
                aksi: "Update_PeriodId"
            })
            .then((resp) => {
                console.log(resp, "resp updatePeriod data");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const joinAndar = () => {
        navigator.vibrate(100);
        const formdata = new FormData();
        formdata.append("username", window.$loginData);
        formdata.append("period", periodID?.period);
        formdata.append("amount", contractMoney);
        formdata.append("card", cardinfo?.card);
        formdata.append("type", "Andar");
        if (contractMoney <= bal?.balance) {
            axios
                .post(`${andar_Url}/bet.php?action=bet`, formdata, {})
                .then((resp) => {
                    console.log(resp, "joinAndar resp data");
                    if (resp?.data[0]?.status === "Bet Added Successfully") {
                        toast.success("Bet Added Successfully", {
                            position: toast.POSITION.BOTTOM_LEFT,
                        });
                        setOpen(false);
                        userInfo()
                        betRecord()
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            toast.error("Insufficient Balance", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
    };

    const joinTie = () => {
        navigator.vibrate(100);
        const formdata = new FormData();
        formdata.append("username", window.$loginData);
        formdata.append("period", periodID?.period);
        formdata.append("amount", contractMoney2);
        formdata.append("card", cardinfo?.card);
        formdata.append("type", "Tie");
        contractMoney2 <= bal?.balance
            ? axios
                .post(`${andar_Url}/bet.php?action=bet`, formdata, {})
                .then((resp) => {
                    // console.log(resp, "joinTie resp data");
                    resp?.data[0]?.status === "Bet Added Successfully" &&
                        toast.success("Bet Added Successfully", {
                            position: toast.POSITION.BOTTOM_LEFT,
                        });
                    setOpen2(false);
                    userInfo()
                    betRecord()
                })
                .catch((err) => {
                    console.log(err);
                })
            : toast.error("Insufficient Balance", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
    };

    const joinBahar = () => {
        navigator.vibrate(100);
        const formdata = new FormData();
        formdata.append("username", window.$loginData);
        formdata.append("period", periodID?.period);
        formdata.append("amount", contractMoney3);
        formdata.append("card", cardinfo?.card);
        formdata.append("type", "Bahar");
        contractMoney3 <= bal?.balance
            ? axios
                .post(`${andar_Url}/bet.php?action=bet`, formdata, {})
                .then((resp) => {
                    // console.log(resp, "joinBahar resp data");
                    resp?.data[0]?.status === "Bet Added Successfully" &&
                        toast.success("Bet Added Successfully", {
                            position: toast.POSITION.BOTTOM_LEFT,
                        });
                    setOpen3(false);
                    userInfo()
                    betRecord()
                })
                .catch((err) => {
                    console.log(err);
                })
            : toast.error("Insufficient Balance", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
    };

    const generateRandomData = () => {
        const newData = [];
        const newData2 = [];
        for (let i = 0; i < 30; i++) {
            // Generate your random data here
            const randomValue = (Math?.floor(100000 + Math?.random() * 900000));
            const randomValue2 = (Math?.floor(1000 + Math?.random() * 9000));
            newData?.push(randomValue);
            newData2?.push(randomValue2);
        }
        setRandomData(newData);
        setRandomData2(newData2);
    };

    const createRandom = () => {
        const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

        const filterArray = numbers?.filter((item) => {
            return item !== +cardinfo?.card
        })
        // console.log(filterArray, "filterArray")

        const shuffled = [
            filterArray[2],
            filterArray[7],
            filterArray[1],
            filterArray[5],
            filterArray[4],
            filterArray[0],
            filterArray[8],
            filterArray[3],
            filterArray[6]
        ];

        setRandomcard({
            num1: shuffled[0],
            num2: shuffled[1],
            num3: shuffled[2],
            num4: shuffled[3],
            num5: shuffled[4],
        })
    }

    React.useEffect(() => {
        cardinfo?.card?.length > 0 && createRandom()
    }, [cardinfo])

    React.useEffect(() => {
        let interval = null;

        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    React.useEffect(() => {
        const DateValue = Date.parse(new Date()) / 1e3;
        const h = 60 - (DateValue % 60);
        var i = h / 60,
            n = h % 60;
        setMinute(Math.floor(i));
        setSecond(("0" + Math.floor(n)).slice(-2));
    });
    // console.log(minute, second, "minute, second");

    React.useEffect(() => {
        if (minute === 0 && second < "11") {
            setOpen(false)
            setOpen2(false)
            setOpen3(false)
        }
        if ((minute === 0 && second === "04") && (resultinfo?.UserBetRecord?.success === true)) {
            setRespopup(true);
        }
        if (minute === 0 && second === "10") {
            andarResult()
        }
        if (minute === 0 && second === "01") {
            updatePeriod()
        }
        if (minute === 1 && second === "00") {
            window?.location?.reload(false);
        }
    }, [minute, second]);

    React.useEffect(() => {
        getCard()
    }, [periodID])

    React.useEffect(() => {
        userInfo();
        generateRandomData()
    }, []);

    return (
        <>
            <ToastContainer />
            <div className="diceHead">
                <i className="fa fa-arrow-left"
                    onClick={() => {
                        navigate(-1)
                    }}
                ></i>
                <h3>Andar Bahar</h3>
                <h4>
                    &#8377;{bal?.balance}
                </h4>
                <i
                    className="fa fa-credit-card"
                    onClick={() => {
                        navigate("/recharge", { state: bal }
                        );
                    }}
                ></i>
                <h4 onClick={() => {
                    setRule(true)
                }}>Rule</h4>
                <Modal
                    open={rule}
                    onClose={() => {
                        setRule(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="diceRule">
                            <h2>Andar Bahar Rule</h2>
                        </div>
                        <div className="diceRuleInfo">
                            <h4>1 minutes 1 issue, 50 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                            <button onClick={() => {
                                setRule(false)
                            }}>GOT IT</button>
                        </div>
                    </Box>
                </Modal>
            </div>
            <div className='andarInfo'>
                <div className="diceBox">
                    <div className="andarPeriod">
                        <h5>Period</h5>
                        <h3>
                            {periodID?.period}
                        </h3>
                    </div>
                    <div className="andarCount">
                        <h5>Count Down</h5>
                        <h3>
                            <span>
                                0{minute}
                            </span>
                            <span>:</span>
                            <span>
                                {second}
                            </span>
                        </h3>
                    </div>
                </div>
                <div className='andarSingle'></div>
                <div className='andarFlex'>
                    <div className='andarTxt'>
                        <h4>Andar</h4>
                    </div>
                    <div className='andarImg'>
                        <img src="avbcards_bk.c77ab2e9.png" alt="" />
                        <div className='andarImg2'>
                            {minute === 0 && second <= "59"
                                &&
                                <img src={`${cardinfo?.card}.png`} className='topAnimate' alt="" />
                            }
                            {minute === 0 && second < "11"
                                &&
                                <img src={`${randomcard?.num1}.png`} className='leftAnimate' alt="" />
                            }
                            {minute === 0 && second < "10"
                                &&
                                <img src={`${randomcard?.num2}.png`} className='rightAnimate' alt="" />
                            }
                            {minute === 0 && second < "09"
                                &&
                                <img src={`${randomcard?.num3}.png`} className='leftAnimate' alt="" />
                            }
                            {minute === 0 && second < "08"
                                &&
                                <img src={`${randomcard?.num4}.png`} className='rightAnimate' alt="" />
                            }
                            {minute === 0 && second < "07"
                                &&
                                <img src={`${randomcard?.num5}.png`} className='leftAnimate' alt="" />
                            }
                            {minute === 0 && second < "06"
                                &&
                                <img src={`${cardinfo?.card}.png`} className={resultinfo?.updated_result === "Andar" ? "leftAnimate" : "rightAnimate"} alt="" />
                            }
                        </div>
                    </div>
                    <div className='andarTxt'>
                        <h4>Bahar</h4>
                    </div>
                </div>
                <div className='andarAllBtn'>
                    <div className="andarBtn">
                        <button
                            onClick={() => {
                                minute === 0 && second < "10"
                                    ? setOpen(false)
                                    : setOpen(true);
                            }}>
                            Andar</button>
                        <Modal
                            open={open}
                            onClose={() => {
                                setOpen(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="diceBlueHead">
                                    <h2>Join Andar</h2>
                                </div>
                                <div className="greenHeadBox">
                                    <div className="greenHeadTxt">
                                        <h5>Contract Money</h5>
                                    </div>
                                    <div className="greenHeadBtn">
                                        {moneydata?.map((item) => {
                                            return (
                                                <>
                                                    <div
                                                        className={
                                                            item?.id === greenbtn?.id ? "clickedColor" : ""
                                                        }
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                setGreenbtn(item);
                                                            }}
                                                        >
                                                            {item?.money}
                                                        </button>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Number</h5>
                                    </div>
                                    <div className="greenHeadCount">
                                        <button
                                            disabled={intvalue <= 1}
                                            onClick={() => {
                                                setIntvalue(intvalue - 1);
                                            }}
                                        >
                                            <i className="fa fa-minus"></i>
                                        </button>
                                        <h3>{intvalue}</h3>
                                        <button
                                            onClick={() => {
                                                setIntvalue(intvalue + 1);
                                            }}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Total Contract Money is {contractMoney}</h5>
                                    </div>
                                    <div className="greenHeadTxt2">
                                        <h4>
                                            <i className="fa fa-check"></i>I agree
                                            <span>PRESALE RULE</span>
                                        </h4>
                                    </div>
                                    <div className="signinModalBtn">
                                        <button
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            CANCEL
                                        </button>
                                        <button onClick={joinAndar}>CONFIRM</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                    <div className="tieBtn">
                        <button
                            onClick={() => {
                                minute === 0 && second < "10"
                                    ? setOpen2(false)
                                    : setOpen2(true);
                            }}
                        >Tie</button>
                        <Modal
                            open={open2}
                            onClose={() => {
                                setOpen2(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="violetHead">
                                    <h2 style={{ background: "#ffa33b" }}>Join Tie</h2>
                                </div>
                                <div className="greenHeadBox">
                                    <div className="greenHeadTxt">
                                        <h5>Contract Money</h5>
                                    </div>
                                    <div className="greenHeadBtn">
                                        {moneydata?.map((item) => {
                                            return (
                                                <>
                                                    <div
                                                        className={
                                                            item?.id === violetbtn?.id ? "clickedColor" : ""
                                                        }
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                setVioletbtn(item);
                                                            }}
                                                        >
                                                            {item?.money}
                                                        </button>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Number</h5>
                                    </div>
                                    <div className="greenHeadCount">
                                        <button
                                            disabled={intvalue2 <= 1}
                                            onClick={() => {
                                                setIntvalue2(intvalue2 - 1);
                                            }}
                                        >
                                            <i className="fa fa-minus"></i>
                                        </button>
                                        <h3>{intvalue2}</h3>
                                        <button
                                            onClick={() => {
                                                setIntvalue2(intvalue2 + 1);
                                            }}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Total Contract Money is {contractMoney2}</h5>
                                    </div>
                                    <div className="greenHeadTxt2">
                                        <h4>
                                            <i className="fa fa-check"></i>I agree
                                            <span>PRESALE RULE</span>
                                        </h4>
                                    </div>
                                    <div className="signinModalBtn">
                                        <button
                                            onClick={() => {
                                                setOpen2(false);
                                            }}
                                        >
                                            CANCEL
                                        </button>
                                        <button onClick={joinTie}>CONFIRM</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                    <div className="baharBtn">
                        <button
                            onClick={() => {
                                minute === 0 && second < "10"
                                    ? setOpen3(false)
                                    : setOpen3(true);
                            }}
                        >Bahar</button>
                        <Modal
                            open={open3}
                            onClose={() => {
                                setOpen3(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="redHead">
                                    <h2>Join Bahar</h2>
                                </div>
                                <div className="greenHeadBox">
                                    <div className="greenHeadTxt">
                                        <h5>Contract Money</h5>
                                    </div>
                                    <div className="greenHeadBtn">
                                        {moneydata?.map((item) => {
                                            return (
                                                <>
                                                    <div
                                                        className={
                                                            item?.id === redbtn?.id ? "clickedColor" : ""
                                                        }
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                setRedbtn(item);
                                                            }}
                                                        >
                                                            {item?.money}
                                                        </button>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Number</h5>
                                    </div>
                                    <div className="greenHeadCount">
                                        <button
                                            disabled={intvalue3 <= 1}
                                            onClick={() => {
                                                setIntvalue3(intvalue3 - 1);
                                            }}
                                        >
                                            <i className="fa fa-minus"></i>
                                        </button>
                                        <h3>{intvalue3}</h3>
                                        <button
                                            onClick={() => {
                                                setIntvalue3(intvalue3 + 1);
                                            }}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Total Contract Money is {contractMoney3}</h5>
                                    </div>
                                    <div className="greenHeadTxt2">
                                        <h4>
                                            <i className="fa fa-check"></i>I agree
                                            <span>PRESALE RULE</span>
                                        </h4>
                                    </div>
                                    <div className="signinModalBtn">
                                        <button
                                            onClick={() => {
                                                setOpen3(false);
                                            }}
                                        >
                                            CANCEL
                                        </button>
                                        <button onClick={joinBahar}>CONFIRM</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div >
            <div className='diceRecordBox'>
                <div className='diceRecordHead'>
                    <h3>Record</h3>
                </div>
                <div className='diceRecord'>
                    {recordinfo?.map((data) => {
                        return (
                            <>
                                <div className='diceOne'>
                                    <h4 style={{ background: "#2b3270", border: "1px solid white" }}>{data?.result}</h4>
                                    <h5>{data?.Last_periodId}</h5>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="pageBtn">
                    <button
                        onClick={() => setPage2(page2 - 1)}
                        disabled={page2 === 1}
                    >
                        <i className="fa fa-angle-left"></i>
                    </button>
                    <button onClick={() => setPage2(page2 + 1)}>
                        <i className="fa fa-angle-right"></i>
                    </button>
                </div>
            </div>
            <Box
                sx={{
                    width: "100%",
                    marginTop: "15px"
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="diceTabs"
                >
                    <Tab label="My Records" {...a11yProps(0)} />
                    <Tab label="All Records" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div className='diceTable'>
                    <table>
                        <thead>
                            <tr>
                                <th>Period</th>
                                <th>Select</th>
                                <th>Result</th>
                                <th>Status</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {betdata?.map((item) => {
                                return (
                                    <>
                                        <tr>
                                            <td>
                                                {item?.period}
                                            </td>
                                            <td><span style={{ background: "#0093ff" }}>{item?.type}</span></td>
                                            <td><span style={{ background: "#0093ff" }}>{item?.result}</span></td>
                                            <td><span style={{ background: "#0093ff" }}>{item?.status}</span></td>
                                            <td>{item?.amount}</td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pageBtn">
                    <button
                        onClick={() => setPage(page - 1)}
                        disabled={page === 1}
                    >
                        <i className="fa fa-angle-left"></i>
                    </button>
                    <button onClick={() => setPage(page + 1)}>
                        <i className="fa fa-angle-right"></i>
                    </button>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className='diceTable'>
                    <table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Bet</th>
                                <th>Multi.</th>
                                <th>Cash Out</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {randomData?.map((item) => {
                                        return (
                                            <>
                                                <div>
                                                    <i className="fa fa-user">
                                                    </i> &nbsp;
                                                    {item}
                                                </div>
                                            </>
                                        )
                                    })}
                                </td>
                                {randomData2?.map((item) => {
                                    return (
                                        <>
                                            <div style={{ color: "white" }}>
                                                <span>&#8377;{item}</span>
                                            </div>
                                        </>
                                    )
                                })}
                                <td>
                                    {randomData2?.map(() => {
                                        return (
                                            <>
                                                <div>
                                                    -
                                                </div>
                                            </>
                                        )
                                    })}
                                </td>
                                <td>
                                    {randomData2?.map(() => {
                                        return (
                                            <>
                                                <div>
                                                    -
                                                </div>
                                            </>
                                        )
                                    })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </TabPanel>
            {
                respopup &&
                <Modal
                    open={respopup}
                    onClose={() => {
                        setRespopup(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='diceResult'>
                            <h2>{resultinfo?.UserBetRecord?.BetRecord?.status === "Success" ? "Win" : "Loss"}</h2>
                            <h3>{resultinfo?.updated_result}</h3>
                        </div>
                        <div className='diceOutput'>
                            <h4>Period</h4>
                            <h4>{resultinfo?.period}</h4>
                        </div>
                        <div className='diceOutput'>
                            <h4>Price</h4>
                            <h4>&#8377;{resultinfo?.UserBetRecord?.BetRecord?.price}</h4>
                        </div>
                        <div className='diceAllout'>
                            <div className='diceOutput'>
                                <h4>Select</h4>
                                <h4>{resultinfo?.UserBetRecord?.BetRecord?.type}</h4>
                            </div>
                            <div className='diceOutput'>
                                <h4>Amount</h4>
                                <h4>&#8377;{resultinfo?.UserBetRecord?.BetRecord?.amount}</h4>
                            </div>
                        </div>
                        <div className='diceClose'>
                            <button onClick={() => { setRespopup(false) }}>Close</button>
                        </div>
                    </Box>
                </Modal>
            }
            <div className="audio">
                {minute === 0 && second < 11 && (
                    <ReactAudioPlayer
                        src="WhatsApp Audio 2023-09-19 at 02.12.53 (1).mpeg"
                        autoPlay
                        controls
                    />
                )}
            </div>
        </>
    )
}

/* AndarBahar CSS */
/* Dice CSS */
