import BottomNav from "../BottomNavigate/BottomNav";

const data = [
  {
    id: 1,
    image: "jewellery-chokker-gold-gold-jewellery.jpg",
    name: "Ratnavali Jewels American Diamond Traditional Fashion...",
    price: "38,250.00",
  },
  {
    id: 2,
    image: "jewellery-chokker-gold-gold-jewellery.jpg",
    name: "Ratnavali Jewels American Diamond Traditional Fashion...",
    price: "38,250.00",
  },
  {
    id: 3,
    image: "jewellery-chokker-gold-gold-jewellery.jpg",
    name: "Ratnavali Jewels American Diamond Traditional Fashion...",
    price: "38,250.00",
  },
  {
    id: 4,
    image: "jewellery-chokker-gold-gold-jewellery.jpg",
    name: "Ratnavali Jewels American Diamond Traditional Fashion...",
    price: "38,250.00",
  },
  {
    id: 5,
    image: "jewellery-chokker-gold-gold-jewellery.jpg",
    name: "Ratnavali Jewels American Diamond Traditional Fashion...",
    price: "38,250.00",
  },
  {
    id: 6,
    image: "jewellery-chokker-gold-gold-jewellery.jpg",
    name: "Ratnavali Jewels American Diamond Traditional Fashion...",
    price: "38,250.00",
  },
];

export default function Search() {
  return (
    <>
      <BottomNav />
      <div className="searchCont">
        <div className="signinInput">
          <input type="text" placeholder="Search for Goods" />
        </div>
        <div className="allProductsBox">
          <div className="allProducts">
            {data.map((item) => {
              return (
                <>
                  <div className="productCard">
                    <img src={item.image} alt="img not available" />
                    <h5>{item.name} </h5>
                    <h5>
                      <i className="fa fa-rupee"></i> {item.price}
                    </h5>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

/* Search CSS */
