import React from 'react';
import './NoInternet.css';

const NoInternet = () => {
  return (
    <div className="no-internet-container">
      <div className="no-internet-content">
        <img src="/happy/noInternet.webp" alt="No Internet" className="no-internet-image" />
        <h1 className="no-internet-title">No Internet Connection</h1>
        <p className="no-internet-message">It seems you're offline. Check your connection and try again.</p>
        <button className="retry-button" onClick={() => window.location.reload()}>Retry</button>
      </div>
    </div>
  );
};

export default NoInternet;