import React, { useState, useCallback, useEffect } from "react";
import { wingo_Url } from "../../../Context/Context";

export default function MyHistoryOneMinute({ triggerApiMyHistoryOneMinute }) {
    const [betdata, setBetdata] = useState([]);
    const [page, setPage] = useState(1);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const betRecord = useCallback(async () => {
        try {
            const response = await fetch(
                `${wingo_Url}/bet.php?action=betrec_2&user=${window?.$loginData}&page=${page}`
            );
            const data = await response.json();
            console.log(data, "Fetched bet data");
            setBetdata(data);
        } catch (error) {
            console.error("Error fetching bet data:", error);
        }
    }, [page]);

    useEffect(() => {
        betRecord();
    }, [triggerApiMyHistoryOneMinute, betRecord]);

    const toggleDetails = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <div className="px-2">
            <div className="appcolorPrediction">
                {betdata.map((bet, index) => (
                    <div key={index}>
                        <div
                            className="transaction-itemcolorPrediction"
                            onClick={() => toggleDetails(index)}
                        >
                            <div
                                className={`iconcolorPrediction ${
                                    bet.number > 4 ? "bigcolorPrediction" : "smallcolorPrediction"
                                }`}
                            >
                                {
                                    bet.ans === "green" ? "G" :
                                    bet.ans === "red" ? "R" :
                                    bet.ans === "violet" ? "V" :
                                    bet.ans === "big" ? "big" : 
                                    bet.ans === "small" ? "small" : 
                                    bet.ans
                                }
                            </div>
                            <div className="detailscolorPrediction">
                                <p className="idcolorPrediction">{bet.period}</p>
                                <p className="timestampcolorPrediction">{bet.time}</p>
                            </div>
                            {bet.res !== "wait" && (
                                <div className="statuscolorPrediction">
                                    <p
                                        className={`status-textcolorPrediction ${
                                            bet.res === "fail"
                                                ? "failedcolorPrediction"
                                                : "succeedcolorPrediction"
                                        }`}
                                    >
                                        {bet.res === "fail" ? "Failed" : "Success"}
                                    </p>
                                    <p
                                        className={`amountcolorPrediction ${
                                            bet.res === "fail" ? "failedcolorPrediction" : "succeedcolorPrediction"
                                        }`}
                                    >
                                        {bet.res === "wait" ? (
                                            (bet.amount - (bet.amount * 2) / 100).toFixed(2)
                                        ) : (
                                            <>
                                                ₹
                                                {bet.ans === "green" && bet.color2 === "" && bet.res === "fail"
                                                    ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                    : ""}
                                                {bet.ans === "green" && bet.color2 === "" && bet.res === "success"
                                                    ? `+${(bet.amount * 2 - (bet.amount * 2 * 5) / 100).toFixed(2)}`
                                                    : ""}
                                                {bet.ans === "violet" && bet.res === "fail"
                                                    ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                    : ""}
                                                {bet.ans === "violet" && bet.res === "success"
                                                    ? `+${(bet.amount * 2 - (bet.amount * 2 * 5) / 100).toFixed(2)}`
                                                    : ""}
                                                {bet.ans === "red" && bet.color2 === "" && bet.res === "fail"
                                                    ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                    : ""}
                                                {bet.ans === "red" && bet.color2 === "" && bet.res === "success"
                                                    ? `+${(bet.amount * 2 - (bet.amount * 2 * 5) / 100).toFixed(2)}`
                                                    : ""}
                                                {!isNaN(bet.ans) && bet.res === "fail"
                                                    ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                    : ""}
                                                {!isNaN(bet.ans) && bet.res === "success"
                                                    ? `+${(bet.amount * 8 - (bet.amount * 8 * 5) / 100).toFixed(2)}`
                                                    : ""}
                                                {bet?.ans === "big" && bet?.res === "fail" &&
                                                    (-(
                                                    bet?.amount - (bet?.amount * 2) / 100
                                                    )).toFixed(2)}
                                                {bet?.ans === "big" && bet?.res === "success" &&
                                                    "+" +
                                                    (
                                                    bet?.amount * 2 - (bet?.amount * 2 * 2) / 100
                                                    ).toFixed(2)}
                                                {bet?.ans === "small" && bet?.res === "fail" &&
                                                    (-(
                                                    bet?.amount - (bet?.amount * 2) / 100
                                                    )).toFixed(2)}
                                                {bet?.ans === "small" && bet?.res === "success" &&
                                                    "+" +
                                                    (
                                                    bet?.amount * 2 - (bet?.amount * 2 * 2) / 100
                                                    ).toFixed(2)}
                                                {bet.ans === "red" && bet.color2 === "violet" && bet.res === "success"
                                                    ? `+${(bet.amount * 1.5 - (bet.amount * 1.5 * 5) / 100).toFixed(2)}`
                                                    : ""}
                                                {bet.ans === "red" && bet.color2 === "violet" && bet.res === "fail"
                                                    ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                    : ""}
                                                {bet.ans === "green" && bet.color2 === "violet" && bet.res === "success"
                                                    ? `+${(bet.amount * 1.5 - (bet.amount * 1.5 * 5) / 100).toFixed(2)}`
                                                    : ""}
                                                {bet.ans === "green" && bet.color2 === "violet" && bet.res === "fail"
                                                    ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                    : ""}
                                            </>
                                        )}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div
                            className={`details-slide mb-1 ${
                                expandedIndex === index ? "expanded" : ""
                            }`}
                        >
                            <div className="details-header">
                                <p style={{ color: "#fff", fontSize: "20px" }}>Details</p>
                            </div>
                            <div className="details-content">
                                <div className="details-row">
                                    <span>Period:</span>
                                    <span>{bet.period}</span>
                                </div>
                                <div className="details-row">
                                    <span>Purchase amount:</span>
                                    <span>₹{bet.amount}</span>
                                </div>
                                <div className="details-row">
                                    <span>Result:</span>
                                    <span>
                                        {bet.number} <span style={{ color: bet.color }}>{bet.color}</span> {" "}
                                        <span
                                            className={
                                                bet.number > 4
                                                    ? "bigcolorPrediction"
                                                    : "smallcolorPrediction"
                                            }
                                        >
                                            {bet.number > 4 ? "Big" : "Small"}
                                        </span>
                                    </span>
                                </div>
                                <div className="details-row">
                                    <span>Select:</span>
                                    <span>{bet.ans}</span>
                                </div>
                                <div className="details-row">
                                    <span>Status:</span>
                                    <span>{bet.res === "fail" ? "Failed" : "Success"}</span>
                                </div>
                                <div className="details-row">
                                    <span>Win/lose:</span>
                                    <span>
                                        <>
                                            ₹
                                            {bet.ans === "green" && bet.color2 === "" && bet.res === "fail"
                                                ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                : ""}
                                            {bet.ans === "green" && bet.color2 === "" && bet.res === "success"
                                                ? `+${(bet.amount * 2 - (bet.amount * 2 * 5) / 100).toFixed(2)}`
                                                : ""}
                                            {bet.ans === "violet" && bet.res === "fail"
                                                ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                : ""}
                                            {bet.ans === "violet" && bet.res === "success"
                                                ? `+${(bet.amount * 2 - (bet.amount * 2 * 5) / 100).toFixed(2)}`
                                                : ""}
                                            {bet.ans === "red" && bet.color2 === "" && bet.res === "fail"
                                                ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                : ""}
                                            {bet.ans === "red" && bet.color2 === "" && bet.res === "success"
                                                ? `+${(bet.amount * 2 - (bet.amount * 2 * 5) / 100).toFixed(2)}`
                                                : ""}
                                            {!isNaN(bet.ans) && bet.res === "fail"
                                                ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                : ""}
                                            {!isNaN(bet.ans) && bet.res === "success"
                                                ? `+${(bet.amount * 8 - (bet.amount * 8 * 5) / 100).toFixed(2)}`
                                                : ""}
                                            {bet?.ans === "big" && bet?.res === "fail" &&
                                                (-(
                                                bet?.amount - (bet?.amount * 2) / 100
                                                )).toFixed(2)}
                                            {bet?.ans === "big" && bet?.res === "success" &&
                                                "+" +
                                                (
                                                bet?.amount * 8 - (bet?.amount * 8 * 2) / 100
                                                ).toFixed(2)}
                                            {bet?.ans === "small" && bet?.res === "fail" &&
                                                (-(
                                                bet?.amount - (bet?.amount * 2) / 100
                                                )).toFixed(2)}
                                            {bet?.ans === "small" && bet?.res === "success" &&
                                                "+" +
                                                (
                                                bet?.amount * 8 - (bet?.amount * 8 * 2) / 100
                                                ).toFixed(2)}
                                            {bet.ans === "red" && bet.color2 === "violet" && bet.res === "success"
                                                ? `+${(bet.amount * 1.5 - (bet.amount * 1.5 * 5) / 100).toFixed(2)}`
                                                : ""}
                                            {bet.ans === "red" && bet.color2 === "violet" && bet.res === "fail"
                                                ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                : ""}
                                            {bet.ans === "green" && bet.color2 === "violet" && bet.res === "success"
                                                ? `+${(bet.amount * 1.5 - (bet.amount * 1.5 * 5) / 100).toFixed(2)}`
                                                : ""}
                                            {bet.ans === "green" && bet.color2 === "violet" && bet.res === "fail"
                                                ? (-(bet.amount - (bet.amount * 2) / 100)).toFixed(2)
                                                : ""}
                                        </>
                                    </span>
                                </div>
                                <div className="details-row">
                                    <span>Order time:</span>
                                    <span>{bet.time}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="GameHistorypageBtn mt-2">
                <button onClick={() => setPage(page - 1)} disabled={page === 1}>
                    <i className="fa fa-angle-left"></i>
                </button>
                <button onClick={() => setPage(page + 1)}>
                    <i className="fa fa-angle-right"></i>
                </button>
            </div>
        </div>
    );
}
