import React, { useEffect, useState } from 'react';
import { Fetch_Url } from "../../Context/Context";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function VipIndex() {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [VipCards, setVipCards] = useState([]);
    const [TotalBetAmount, setTotalBetAmount] = useState(0);
    const [VipLevelBenefits, setVipLevelBenefits] = useState([]);
    const [isLoadingBenefits, setIsLoadingBenefits] = useState(false);

    const getVIPLevels = () => {
        const username = localStorage.getItem("loginData");
        axios
            .post(`${Fetch_Url}/Index.php`, {
                aksi: "getVIPLevels",
                username: username
            })
            .then((resp) => {
                const totalBetAmount = resp?.data?.total_bet_amount || 0;
                setTotalBetAmount(totalBetAmount);
                setVipCards(resp?.data?.result || []);
            })
            .catch((err) => {
                console.log("Error fetching VIP levels:", err);
            });
    };

    const getVIPLevelsBenefits = (vip_category_id) => {
        setIsLoadingBenefits(true);
        const username = localStorage.getItem("loginData");

        axios
            .post(`${Fetch_Url}/Index.php`, {
                aksi: "getVIPBenefitsOnTheBehalfOfTheVIPCategoryId",
                vip_category_id: vip_category_id,
                username: username
            })
            .then((resp) => {
                setVipLevelBenefits(resp?.data?.result || []);
            })
            .catch((err) => {
                console.log("Error fetching VIP level benefits:", err);
            })
            .finally(() => {
                setIsLoadingBenefits(false);
            });
    };    

    useEffect(() => {
        getVIPLevels();
    }, []);

    useEffect(() => {
        if (VipCards.length > 0) {
            const vipCategoryId = VipCards[activeIndex]?.id;
            getVIPLevelsBenefits(vipCategoryId);
        }
    }, [activeIndex, VipCards]);

    const handleScroll = (e) => {
        const scrollLeft = e.target.scrollLeft;
        const cardWidth = e.target.offsetWidth;
        const index = Math.round(scrollLeft / cardWidth);
        setActiveIndex(index);
    };

    const getProgressBarClass = (theme) => {
        const themeMap = {
            'VIPvip-card-silver': 'VIPprogress-bar-silver',
            'VIPvip-card-golden': 'VIPprogress-bar-golden',
            'VIPvip-card-pink': 'VIPprogress-bar-pink',
            'VIPvip-card-sky-blue': 'VIPprogress-bar-sky-blue',
            'VIPvip-card-purple': 'VIPprogress-bar-purple',
            'VIPvip-card-light-green': 'VIPprogress-bar-light-green',
            'VIPvip-card-dark-green': 'VIPprogress-bar-dark-green',
            'VIPvip-card-dark-blue': 'VIPprogress-bar-dark-blue',
            'VIPvip-card-purple-pink-gradient': 'VIPprogress-bar-purple-plus-pink-gradient',
            'VIPvip-card-yellow-orange-gradient': 'VIPprogress-bar-yellow-plus-orange-gradient'
        };
        return themeMap[theme] || '';
    };

    const getLevelProgressClass = (theme) => {
        const themeMap = {
            'VIPvip-card-silver': 'level-progress-silver',
            'VIPvip-card-golden': 'level-progress-golden',
            'VIPvip-card-pink': 'level-progress-pink',
            'VIPvip-card-sky-blue': 'level-progress-sky-blue',
            'VIPvip-card-purple': 'level-progress-purple',
            'VIPvip-card-light-green': 'level-progress-light-green',
            'VIPvip-card-dark-green': 'level-progress-dark-green',
            'VIPvip-card-dark-blue': 'level-progress-dark-blue',
            'VIPvip-card-purple-pink-gradient': 'level-progress-purple-plus-pink-gradient',
            'VIPvip-card-yellow-orange-gradient': 'level-progress-yellow-plus-orange-gradient'
        };
        return themeMap[theme] || '';
    };

    const calculateProgressWidth = (vip_invested_amount) => {
        if (vip_invested_amount === 0) return 0;
        const progress = (TotalBetAmount / vip_invested_amount) * 100;
        return Math.min(progress, 100);
    };
    
    const handleCollectVIPRewards = async (rewardType, vipCategoryId, vipMyBenefitsId, rewardAmount) => {
        try {
            const username = localStorage.getItem("loginData");
            
            const response = await axios.post(`${Fetch_Url}/Index.php`, {
                aksi: "collectVIPReward",
                username: username,
                vip_category_id: vipCategoryId,
                vip_my_benefits_id: vipMyBenefitsId,
                reward_type: rewardType,
                amount: rewardAmount,
                message: rewardType === "Level Up Reward" ? "Level Up Reward Claimed Successfully" : "Monthly Reward Claimed Successfully",
            });
    
            if (response.data.success) {
                toast.success(response.data.msg);
                getVIPLevelsBenefits(vipCategoryId);

            } else {
                toast.error(response.data.msg || "Failed to collect the reward.");
            }
        } catch (error) {
            toast.error("An unexpected error occurred. Please try again.");
        }
    };    

    const handleAlreadyCollected = () => {
        toast.success("Reward Already Collected")
    }

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <>
            <ToastContainer />
            <div className="VIPTopHeadervip-header-container">
                <button className="VIPTopHeaderback-button" onClick={handleBack}>
                    <i class="fa fa-arrow-left" style={{ fontSize: "20px" }}></i>
                </button>
                <h2 className="VIPTopHeadervip-title">VIP</h2>
            </div>
            <div className="VIPcarousel-container">
                <div
                    className="VIPcarousel"
                    onScroll={handleScroll}
                    style={{ overflowX: 'scroll', display: 'flex', scrollSnapType: 'x mandatory' }}
                >
                    {VipCards.map((card, index) => {
                        const progressWidth = Math.round(calculateProgressWidth(card.vip_invested_amount));

                        return (
                            <div
                                key={index}
                                className={`VIPvip-card-center ${index === activeIndex ? 'active' : 'inactive'}`}
                                style={{ scrollSnapAlign: 'center', flexShrink: 0, width: '350px' }}
                            >
                                <div className={card.vip_card_theme}>
                                    <div className="VIPvip-header">
                                        <img src="/happy/CrownForVIP.png" alt="VIP Icon" />
                                        <h1 className="level2">{card.vip_level_title}</h1>
                                    </div>
                                    <div className="VIPvip-subtext">
                                        Dear <strong>{card.vip_level_title}</strong> customer
                                    </div>
                                    <div className="VIPinfo">
                                        <span>Level maintenance</span>
                                    </div>
                                    <div className="VIPinfo">
                                        <span className={getLevelProgressClass(card.vip_card_theme)}>
                                            <strong>{TotalBetAmount}/{card.vip_invested_amount}</strong>
                                        </span>
                                        <span>{progressWidth}% {card.status}</span>
                                    </div>
                                    <div className={getProgressBarClass(card.vip_card_theme)}>
                                        <span style={{ width: `${progressWidth}%` }}></span>
                                    </div>
                                    <div className="VIPdeduction">
                                        {card.vip_bottom_description}
                                    </div>
                                    <img
                                        src={`/happy/${card.vip_star_image}.png`}
                                        alt="Star Icon"
                                        style={{ position: 'absolute', top: '10px', right: '10px', width: '80px' }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                {isLoadingBenefits ? (
                    <div className="VIPBenefitspreloader-overlay">
                        <div className="VIPBenefitspreloader"></div>
                    </div>
                ) : (
                    <>
                        <div className="VIPBenefitslevel-up-container-bg mb-3">
                            <div className="VIPLevelBenefitsTitle">
                                    <img src="/happy/VipDiamond.png" style={{ width: "20px" }} /> <h2 style={{ marginLeft: "5px" }}>{VipLevelBenefits.vip_level_title}</h2>
                            </div>
                            <div className="VIPLevelBenefitsTitleHr"></div>
                            <div className="VIPBenefitslevel-up-container">
                                <div className="VIPBenefitsreward-icon">
                                    <div className="VIPBenefitsgift-icon"></div>
                                </div>
                                <div className="VIPBenefitsreward-info">
                                    <div className="VIPBenefitsreward-title">
                                        Level up rewards
                                    </div>
                                    <div className="VIPBenefitsreward-subtitle">
                                        Each account can only receive 1 time
                                    </div>
                                </div>
                                <div className="VIPBenefitsreward-values">
                                    <div className="VIPBenefitsvalue-box VIPBenefitstop-box">
                                        <span className="VIPBenefitsvalue">
                                            <img src="/happy/BalanceWallet.png" style={{ width: "18px" }} /> {VipLevelBenefits.vip_level_up_rewards}
                                        </span>
                                    </div>
                                    <div className="VIPBenefitsvalue-box VIPBenefitsbottom-box">
                                        <span className="VIPBenefitsvalue">
                                            <img src="/happy/VipLevelBenefits.png" style={{ width: "18px" }} /> 0
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="VIPBenefitslevel-up-container">
                                <div className="VIPBenefitsreward-icon">
                                    <div className="VIPBenefitsstarcoin-icon"></div>
                                </div>
                                <div className="VIPBenefitsreward-info">
                                    <div className="VIPBenefitsreward-title">
                                        Monthly reward
                                    </div>
                                    <div className="VIPBenefitsreward-subtitle">
                                        Each account can only receive 1 time per month
                                    </div>
                                </div>
                                <div className="VIPBenefitsreward-values">
                                    <div className="VIPBenefitsvalue-box VIPBenefitstop-box">
                                        <span className="VIPBenefitsvalue">
                                            <img src="/happy/BalanceWallet.png" style={{ width: "18px" }} /> {VipLevelBenefits.vip_monthly_rewards}
                                        </span>
                                    </div>
                                    <div className="VIPBenefitsvalue-box VIPBenefitsbottom-box">
                                        <span className="VIPBenefitsvalue">
                                            <img src="/happy/VipLevelBenefits.png" style={{ width: "18px" }} /> 0
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="VIPBenefitslevel-up-container">
                                <div className="VIPBenefitsreward-icon">
                                    <div className="VIPBenefitssafe-icon"></div>
                                </div>
                                <div className="VIPBenefitsreward-info">
                                    <div className="VIPBenefitsreward-title">
                                        Safe
                                    </div>
                                    <div className="VIPBenefitsreward-subtitle">
                                        Increase the extra income of the safe
                                    </div>
                                </div>
                                <div className="VIPBenefitsreward-values">
                                    <div className="VIPBenefitsvalue-box VIPBenefitstop-box">
                                        <span className="VIPBenefitsvalue">
                                            <img src="/happy/Safe.png" style={{ width: "18px" }} /> {VipLevelBenefits.vip_safe}%
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="VIPBenefitslevel-up-container">
                                <div className="VIPBenefitsreward-icon">
                                    <div className="VIPBenefitsrebate-icon"></div>
                                </div>
                                <div className="VIPBenefitsreward-info">
                                    <div className="VIPBenefitsreward-title">
                                        Rebate rate
                                    </div>
                                    <div className="VIPBenefitsreward-subtitle">
                                        Increase income of rebate
                                    </div>
                                </div>
                                <div className="VIPBenefitsreward-values">
                                    <div className="VIPBenefitsvalue-box VIPBenefitstop-box">
                                        <span className="VIPBenefitsvalue">
                                            <img src="/happy/RebateRate.png" style={{ width: "18px" }} /> {VipLevelBenefits.vip_rebate_rate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="VIPBenefitslevel-up-container-bg mb-3">
                            <div className="VIPLevelBenefitsTitle">
                                <img src="/happy/BlueCrownForVIP.png" style={{ width: "20px" }} /> <h2 style={{ marginLeft: "5px" }}>My Benefits</h2>
                            </div>
                            <div className="VIPLevelBenefitsTitleHr"></div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="VIPMyBenebitscard">
                                        <img src="happy/welfare1-eee87ee1.png" alt="Level Up Rewards" />
                                        <div class="VIPMyBenebitscontent">
                                            <div class="reward-bar">
                                                <div class="reward-item">
                                                    <img src="happy/gold-4a60a059.png" alt="Folder Icon" style={{ width: "20px", height: "20px", backgroundColor: "transparent" }} class="reward-icon" />
                                                    <span class="reward-value">{VipLevelBenefits.vip_level_up_rewards}</span>
                                                </div>
                                                <div class="reward-item">
                                                    <img src="happy/love2-83aaa90e.png" alt="Gem Icon" style={{ width: "20px", height: "20px", backgroundColor: "transparent" }} class="reward-icon" />
                                                    <span class="reward-value">0</span>
                                                </div>
                                            </div>
                                            <h3>Level Up Rewards</h3>
                                            <p>Each account can only receive 1 time.</p>
                                            <div className="px-2 pb-3">
                                                {VipLevelBenefits.levelUpstatus === "InComplete" ? (
                                                    <button className="gradient-bg-btn-silver">Incomplete</button>
                                                ) : VipLevelBenefits.levelUpstatus === "Claimed" ? (
                                                    <button className="gradient-bg-btn" onClick={handleAlreadyCollected}>Claimed</button>
                                                ) : (
                                                    <button
                                                        className="gradient-bg-btn"
                                                        onClick={() => handleCollectVIPRewards(
                                                            "Level Up Reward",
                                                            VipLevelBenefits.vip_category_id,
                                                            VipLevelBenefits.id,
                                                            VipLevelBenefits.vip_level_up_rewards
                                                        )}
                                                    >
                                                        Received
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="VIPMyBenebitscard">
                                        <img src="happy/welfare2-cf757d28.png" alt="Monthly Reward" />
                                        <div class="VIPMyBenebitscontent">
                                            <div class="reward-bar">
                                                <div class="reward-item">
                                                    <img src="happy/gold-4a60a059.png" alt="Folder Icon" style={{ width: "20px", height: "20px", backgroundColor: "transparent" }} class="reward-icon" />
                                                    <span class="reward-value">{VipLevelBenefits.vip_monthly_rewards}</span>
                                                </div>
                                                <div class="reward-item">
                                                    <img src="happy/love2-83aaa90e.png" alt="Gem Icon" style={{ width: "20px", height: "20px", backgroundColor: "transparent" }} class="reward-icon" />
                                                    <span class="reward-value">0</span>
                                                </div>
                                            </div>
                                            <h3>Monthly Reward</h3>
                                            <p>Each account can only receive 1 time per month.</p>
                                            <div className="px-2 pb-3">
                                                {VipLevelBenefits.MonthlyRewardStatus === "InComplete" ? (
                                                    <button className="gradient-bg-btn-silver">Incomplete</button>
                                                ) : VipLevelBenefits.MonthlyRewardStatus === "Claimed" ? (
                                                    <button className="gradient-bg-btn" onClick={handleAlreadyCollected}>Claimed</button>
                                                ) : (
                                                    <button
                                                        className="gradient-bg-btn"
                                                        onClick={() => handleCollectVIPRewards(
                                                            "Monthly Reward",
                                                            VipLevelBenefits.vip_category_id,
                                                            VipLevelBenefits.id,
                                                            VipLevelBenefits.vip_monthly_rewards
                                                        )}
                                                    >
                                                        Received
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 mt-3">
                                    <div class="VIPMyBenebitscard">
                                        <img src="happy/welfare4-5642a4c8.png" alt="Safe" />
                                        <div class="VIPMyBenebitscontent">
                                            <div class="reward-bar">
                                                <div class="reward-item">
                                                    <img src="happy/gold-4a60a059.png" alt="Folder Icon" style={{ width: "20px", height: "20px", backgroundColor: "transparent" }} class="reward-icon" />
                                                    <span class="reward-value">{VipLevelBenefits.vip_safe}%</span>
                                                </div>
                                            </div>
                                            <h3>Safe</h3>
                                            <p>Increase the extra income of the safe.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 mt-3">
                                    <div class="VIPMyBenebitscard">
                                        <img src="happy/welfare5-8b250748.png" alt="Rebate Rate" />
                                        <div class="VIPMyBenebitscontent">
                                            <div class="reward-bar">
                                                <div class="reward-item">
                                                    <img src="happy/gold-4a60a059.png" alt="Folder Icon" style={{ width: "20px", height: "20px", backgroundColor: "transparent" }} class="reward-icon" />
                                                    <span class="reward-value">{VipLevelBenefits.vip_rebate_rate}%</span>
                                                </div>
                                            </div>
                                            <h3>Rebate Rate</h3>
                                            <p>Increase income of rebate.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
