import { useNavigate } from "react-router-dom";

export default function Intrest() {
  const navigate = useNavigate();

  return (
    <>
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Intrest</h3>
        <i className="fa fa-question-circle"></i>
      </div>
      <div className="rewardText">
        <h5>No Data Available</h5>
      </div>
    </>
  );
}

/* Reward CSS Used In Intrest CSS*/
