import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetch_Url } from "../../../Context/Context";
import axios from "axios";

export default function DailyTasks() {
    const navigate = useNavigate();
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch activities from the API
    const fetchActivities = () => {
        const username = localStorage.getItem("loginData");

        axios
            .post(`${Fetch_Url}/Index.php`, { aksi: "fetchActivities", username: username })
            .then((resp) => {
                // Check if the response was successful
                if (resp?.data?.success) {
                    setActivities(resp.data.data); // Set the activities data
                } else {
                    console.log("Error fetching activities:", resp?.data?.message);
                }
                setLoading(false); // Set loading to false once data is fetched
            })
            .catch((err) => {
                console.log(err);
                setLoading(false); // Set loading to false even if an error occurs
            });
    };

    useEffect(() => {
        fetchActivities();
    }, []);

    const handleCollectTaskReward = async (activityId) => {
        try {
            const username = localStorage.getItem("loginData");
            if (!username) {
                toast.error("User not logged in. Please log in to collect rewards.");
                return;
            }
            
            const response = await axios.post(`${Fetch_Url}/Index.php`, {
                aksi: "collectTaskReward",
                username: username,
                activityId: activityId
            });

            if (response.data.success) {
                toast.success(response.data.msg || "Reward Collected Successfully!");
                fetchActivities();
            } else {
                toast.error(response.data.msg || "Failed to collect the reward.");
            }
        } catch (error) {
            console.error("Error collecting reward:", error);
            toast.error("An unexpected error occurred. Please try again.");
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="minibg">
                <div className="miniHeadforWallet">
                    <i
                        className="fa fa-arrow-left"
                        onClick={() => navigate(-1)}
                    ></i>
                    <h3>Daily Tasks</h3>
                </div>
            </div>

            <div className="WalletbalCont">
                <div className="GiftTopBannertask-banner">
                    <div>
                        <p>
                            <div className="GiftTopBannerbanner-title">
                                Activity Award
                            </div>
                            <div className="GiftTopBannerbanner-content">
                                <div>
                                    Complete weekly/daily tasks to receive <br /> rich rewards.
                                </div>
                                <div>
                                    Weekly rewards cannot be accumulated <br /> to the next week, and daily rewards <br /> cannot be accumulated to the next day.
                                </div>
                            </div>
                        </p>
                    </div>
                </div>

                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <div className="Giftstask-list">
                        {activities.length > 0 ? (
                            activities.map((activity, index) => {
                                // Calculate the progress percentage
                                const progressPercentage = (activity.totalSpent / activity.investedAmount) * 100;
                                const progressWidth = progressPercentage > 100 ? 100 : progressPercentage; // Ensure the width doesn't exceed 100%

                                return (
                                    <div className="Giftstask-card" key={index}>
                                        <div className="Giftsheader Giftsweekly">
                                            <span className="Giftstitle" style={{ fontSize: "20px" }}>
                                                {activity.missionType} tasks
                                            </span>
                                            <span className="Giftsstatus">{activity.MissionStatus || "Incomplete"}</span>
                                        </div>
                                        <div className="flex Giftsprogress-section" style={{ marginTop: "8px" }}>
                                            <img src="happy/GiftBall.png" className="Giftsball" alt="Gift Ball" />
                                            <span className="Giftsprogress-text">
                                                {`${activity.missionType} mission betting tasks ${activity.totalSpent}/${activity.investedAmount}`}
                                            </span>
                                        </div>
                                        <div className="Giftsprogress-bar" style={{ marginTop: "8px" }}>
                                            {/* Dynamically set the width of the progress bar */}
                                            <div className="Giftsprogress" style={{ width: `${progressWidth}%` }}></div>
                                        </div>
                                        <div className="Giftsaward-section" style={{ marginTop: "3px" }}>
                                            <span className="Giftsaward">Award amount</span>
                                            <div className="Giftsaward-amount">
                                                <img src="happy/BalanceWallet.png" alt="Balance Wallet" className="Giftswallet-icon" />
                                                <span className="Giftsamount">{activity.bonusAmount}</span>
                                            </div>
                                        </div>
                                        <hr className="hrGifts" />
                                        {activity.MissionStatus === "InCompleted" ? (
                                            <button className="Giftscomplete-btn" onClick={() => navigate('/')}>
                                                To Complete
                                            </button>
                                        ) : (
                                            <button 
                                                className="Giftscomplete-btn" 
                                                onClick={() => { 
                                                    handleCollectTaskReward(activity.id); 
                                                }}
                                            >
                                                Completed
                                            </button>
                                        )}
                                    </div>
                                );
                            })
                        ) : (
                            <div>No activities found</div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
